exports.components = {
  "component---src-pages-5-x-5-walls-tsx": () => import("./../../../src/pages/5X5-walls.tsx" /* webpackChunkName: "component---src-pages-5-x-5-walls-tsx" */),
  "component---src-pages-abstraction-2018-tsx": () => import("./../../../src/pages/abstraction-2018.tsx" /* webpackChunkName: "component---src-pages-abstraction-2018-tsx" */),
  "component---src-pages-abstraction-2023-tsx": () => import("./../../../src/pages/abstraction-2023.tsx" /* webpackChunkName: "component---src-pages-abstraction-2023-tsx" */),
  "component---src-pages-allot-five-walls-melbourne-2016-tsx": () => import("./../../../src/pages/allot-five-walls-melbourne-2016.tsx" /* webpackChunkName: "component---src-pages-allot-five-walls-melbourne-2016-tsx" */),
  "component---src-pages-drawing-folio-3-abstraction-2019-tsx": () => import("./../../../src/pages/drawing-folio-3-abstraction-2019.tsx" /* webpackChunkName: "component---src-pages-drawing-folio-3-abstraction-2019-tsx" */),
  "component---src-pages-five-walls-melbourne-2016-tsx": () => import("./../../../src/pages/five-walls-melbourne-2016.tsx" /* webpackChunkName: "component---src-pages-five-walls-melbourne-2016-tsx" */),
  "component---src-pages-five-walls-melbourne-2017-tsx": () => import("./../../../src/pages/five-walls-melbourne-2017.tsx" /* webpackChunkName: "component---src-pages-five-walls-melbourne-2017-tsx" */),
  "component---src-pages-images-20-x-20-aluminium-wall-reliefs-tsx": () => import("./../../../src/pages/images/20x20-aluminium-wall-reliefs.tsx" /* webpackChunkName: "component---src-pages-images-20-x-20-aluminium-wall-reliefs-tsx" */),
  "component---src-pages-images-40-x-50-aluminium-wall-reliefs-group-of-7-tsx": () => import("./../../../src/pages/images/40x50-aluminium-wall-reliefs-group-of-7.tsx" /* webpackChunkName: "component---src-pages-images-40-x-50-aluminium-wall-reliefs-group-of-7-tsx" */),
  "component---src-pages-images-40-x-50-aluminium-wall-reliefs-group-of-8-tsx": () => import("./../../../src/pages/images/40x50-aluminium-wall-reliefs-group-of-8.tsx" /* webpackChunkName: "component---src-pages-images-40-x-50-aluminium-wall-reliefs-group-of-8-tsx" */),
  "component---src-pages-images-60-x-60-galvanised-wall-reliefs-tsx": () => import("./../../../src/pages/images/60x60-galvanised-wall-reliefs.tsx" /* webpackChunkName: "component---src-pages-images-60-x-60-galvanised-wall-reliefs-tsx" */),
  "component---src-pages-images-index-tsx": () => import("./../../../src/pages/images/index.tsx" /* webpackChunkName: "component---src-pages-images-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-monash-university-melbourne-2012-15-tsx": () => import("./../../../src/pages/monash-university-melbourne-2012-15.tsx" /* webpackChunkName: "component---src-pages-monash-university-melbourne-2012-15-tsx" */),
  "component---src-pages-monash-university-melbourne-2015-tsx": () => import("./../../../src/pages/monash-university-melbourne-2015.tsx" /* webpackChunkName: "component---src-pages-monash-university-melbourne-2015-tsx" */),
  "component---src-pages-objects-for-the-wall-2018-tsx": () => import("./../../../src/pages/objects-for-the-wall-2018.tsx" /* webpackChunkName: "component---src-pages-objects-for-the-wall-2018-tsx" */),
  "component---src-pages-objects-for-the-wall-continued-2019-tsx": () => import("./../../../src/pages/objects-for-the-wall-continued-2019.tsx" /* webpackChunkName: "component---src-pages-objects-for-the-wall-continued-2019-tsx" */),
  "component---src-pages-support-18-tsx": () => import("./../../../src/pages/support-18.tsx" /* webpackChunkName: "component---src-pages-support-18-tsx" */)
}

